import { ConversationMessageBodyPart } from '../types/conversation'

export const IS_PROD =
  process.env.NODE_ENV === 'development' || String(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) === 'main'

export const IS_DEV =
  process.env.NODE_ENV === 'development' || String(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF) === 'dev'

export const IS_SANDBOX = process.env.NODE_ENV === 'development' || (!IS_PROD && !IS_DEV)

export const IS_LOCAL = process.env.NODE_ENV === 'development'

export const FORMAT_DATE_ONLY = 'dd MMM yyyy'
export const FORMAT_DATE_MONTH = 'MMM dd'
export const FORMAT_DATE_API = 'yyyy-MM-dd'
export const FORMAT_TIME_API = 'HH:mm'
export const FORMAT_DATE_WITH_TIME = 'dd MMM yy HH:mm'

export const DEFAULT_PAGE_SIZE = 10
export const EMPTY_ARRAY: any[] = []

export const MESSAGE_TEXT_TYPES: ConversationMessageBodyPart['body_part_type'][] = ['text', 'email', 'link', 'phone']
