import { FC } from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
  children: React.ReactNode
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Root = styled.div`
  display: inline-flex;
  animation: ${spin} 1s linear infinite;
`

const AnimateSpinWrapper: FC<Props> = ({ children }) => {
  return <Root>{children}</Root>
}

export default AnimateSpinWrapper
