import { FC } from 'react'
import AnimateSpinWrapper from '../AnimateSpinWrapper'

interface Props {}

const Spinner: FC<Props> = ({}) => {
  return (
    <AnimateSpinWrapper>
      <i className="bi-arrow-clockwise" />
    </AnimateSpinWrapper>
  )
}

export default Spinner
